<template lang="pug">
div
  .costs-list-title(
     @click="setActive('VAT credit')"
  )
    span
      i.fas.fa-chevron-right.costs-list-chevron
      span.costs-list-title-text {{ $t("financeForms.vatCredit") }}
    span.costs-list-price
     span.big {{ costPrice }}
     | &nbsp;&nbsp;{{ currencySymbol }}/{{ $t('mon') }}

  .costs-list-content
    .costs-list-prices
      div
        .costs-list-subtitle.m-t-20
          strong {{ firstStepStyle.total_price_title }}
        .m-t-15.big {{ formatPrice(totalPrice) }} {{ currencySymbol }}
      div
        .costs-list-subtitle.m-t-20
          strong {{ firstStepStyle.total_vat_title }}
        .m-t-15.big {{ formatPrice(totalVat) }} {{ currencySymbol }}
      div
        .costs-list-subtitle.m-t-20
          strong {{ firstStepStyle.credit_amount_title }}
        .m-t-15.big {{ formatPrice(totalPrice - calculatedCashPaymentPrice) }} {{ currencySymbol }}
      div
        .costs-list-subtitle.m-t-20
          strong {{ firstStepStyle.interest_title }}
        .m-t-15.big {{ rate }} %

    .costs-list-subtitle.m-t-50
      strong {{ firstStepStyle.cash_payment_title }}, {{ currencySymbol }} {{ !getIsVatIncluded ? $t('exmoms') : '' }}:
    .row.m-t-40
      .costs-list-slider.col-md-12
        vue-slider(
          :min="minCashPayment"
          :max="maxCashPayment"
          v-model="cashPayment"
          :dotSize="slider.dotSize"
          :height="slider.height"
          :silent="true"
          :contained="true"
          :drag-on-click="true"
          :tooltip="'always'"
          @drag-end="sliderChange($event)"
          :tooltip-formatter="formatter2"
        )

    div(v-if="getResidualVisibility")
      .costs-list-subtitle.m-t-20
        strong {{ firstStepStyle.residual_title }}, {{ currencySymbol }} {{ !getIsVatIncluded ? $t('exmoms') : '' }}:
        .infotooltip
          .infotooltip-content {{ firstStepStyle.residual_info_popup_text }}

      .row.m-t-50
        .costs-list-slider.col-md-12
          vue-slider(
            :min="minResidual"
            :max="maxResidual"
            v-model="residual"
            :dotSize="slider.dotSize"
            :height="slider.height"
            :silent="true"
            :contained="true"
            :drag-on-click="true"
            :tooltip="'always'"
            @drag-end="sliderChange($event)"
            :tooltip-formatter="formatter1"
          )

    .costs-list-subtitle.m-t-50
        strong {{ firstStepStyle.payment_info_title }}
        .infotooltip(@click="showPopup")
          .infotooltip-content {{ $t("steps.residual.info") }}
    .costs-list-prices.costs-list-vat-prices
      div
        .costs-list-subtitle.m-t-20
          strong {{ $t("month") }} 1-2
        .m-t-15.big {{ formatPrice(month12) }} {{ currencySymbol }}
      div
        .costs-list-subtitle.m-t-20
          strong {{ $t("month") }} 3
        .m-t-15.big {{ formatPrice(month3) }} {{ currencySymbol }}
      div
        .costs-list-subtitle.m-t-20
          strong {{ $t("month") }} 4-36
        .m-t-15.big {{ formatPrice(month436) }} {{ currencySymbol }}

    MonthChoiser(
      :fixedCostsBy="fixedCostsByDistance"
      :showPices="false"
    )

  popup.transparent(:show="isPopup" @click="closePopup" :textcolor="firstStepStyle.info_popup_text_color" :bgcolor="firstStepStyle.info_popup_bg_color")
    .content {{ firstStepStyle.payment_info_popup_text }}
</template>

<script>
import CostsMixin from '@/mixins/CostsMixin'
import CostsSliderMixin from '@/mixins/CostsSliderMixin.js'

export default {
  components: {
    MonthChoiser: () => import('./../costs-parts/month'),
    Popup: () => import('@/components/helps/Popup'),
  },
  mixins: [CostsMixin, CostsSliderMixin],
  data: () => ({
    isPopup: false,
  }),
  computed: {
    costPrice() {
      return this.getFormCostPrice('VAT credit')
    },
    month12() {
      return this.vehicle?.paymentInfo ? this.vehicle.paymentInfo['1'] : 0
    },
    month3() {
      return this.vehicle?.paymentInfo ? this.vehicle.paymentInfo['3'] : 0
    },
    month436() {
      return this.vehicle?.paymentInfo ? this.vehicle.paymentInfo['4'] : 0
    },
    totalVat() {
      return (
        this.getColorAndAccessoryVat('VAT credit') +
        (this.month3 - this.month12)
      )
    },
    formatter1() {
      return (
        this.formatPrice(this.calculatedResidualPrice) +
        ' kr (' +
        this.residual +
        '%)'
      )
    },
    formatter2() {
      return (
        this.formatPrice(this.calculatedCashPaymentPrice) +
        ' kr (' +
        this.cashPayment +
        '%)'
      )
    },
  },
  methods: {
    showPopup() {
      this.$set(this.$data, 'isPopup', true)
    },
    closePopup() {
      this.$set(this.$data, 'isPopup', false)
    },
    formatPrice(num) {
      if (!num || num === 0) return num

      return Math.round(num).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
  },
}
</script>
